<template>
  <div class="gameover bg-black" v-if="gameOver">
    <div class="relative flex justify-center">
      <img
        :src="require('@/assets/images/illustration-game-over.svg')"
        class="absolute top-6 transform left-1/2 -translate-x-1/2"
      />
      <p
        class="font-ZuumeSemiBold text-6xl text-nColorYellow text-shadow-orange-right pt-36 z-20 game-over-text"
      >
        game over!
      </p>
    </div>
    <div class="relative p-4 mt-8 h-64">
      <img
        :src="require('@/assets/images/gameover-taco.svg')"
        class="w-1/5 absolute -top-2 left-2 z-20"
      />
      <img
        :src="require('@/assets/images/gameover-ham.svg')"
        class="w-1/5 absolute bottom-4 right-2 z-20"
      />
      <div
        class="bg-nColorYellow w-24 h-24 rounded-2xl border border-nColorDarkGray shadow-black-right absolute z-20 -top-6 transform left-1/3 -translate-1/3 ml-4"
      >
        <div class="grid justify-items-center text-center py-2">
          <p class="leading-4 font-ZuumeSemiBold text-lg">
            Your current ranking
          </p>
          <p class="font-ZuumeSemiBold text-5xl">
            <span v-if="gameOver.leaderboard.customer_position">{{
              gameOver.leaderboard.customer_position
            }}</span>
            <span v-else>N/A</span>
          </p>
        </div>
      </div>
      <div class="w-full bg-nColorOrange rounded-xl triple-border py-3">
        <div class="flex gap-2 justify-evenly mt-12">
          <div
            class="grid justify-items-center text-center border-r border-nColorDarkGray pr-4"
          >
            <p class="font-ZuumeSemiBold text-xl">last score</p>
            <p class="font-ZuumeBold text-3xl">
              {{ gameOver.last_score.score }}
            </p>
            <p v-if="gameOver.last_score.time" class="font-ZuumeBold text-sm">
              {{ timeTaken(gameOver.last_score.time) }}
            </p>
          </div>
          <div
            class="grid justify-items-center text-center border-r border-nColorDarkGray pr-4 content-start"
          >
            <p class="font-ZuumeSemiBold text-xl">highest score</p>
            <p class="font-ZuumeBold text-3xl">
              {{ gameOver.leaderboard.customer_results.highest.score }}
            </p>
            <p
              v-if="gameOver.leaderboard.customer_results.highest.time"
              class="font-ZuumeBold text-sm"
            >
              {{
                timeTaken(gameOver.leaderboard.customer_results.highest.time)
              }}
            </p>
          </div>
          <div
            class="grid justify-items-center text-center content-start"
            v-if="gameOver.leaderboard.results[0]"
          >
            <p class="font-ZuumeSemiBold text-xl">beat to win</p>
            <p class="font-ZuumeBold text-3xl">
              {{ gameOver.leaderboard.results[0].highest_score.score }}
            </p>
            <p
              v-if="gameOver.leaderboard.results[0].highest_score.time"
              class="font-ZuumeBold text-sm"
            >
              {{
                timeTaken(gameOver.leaderboard.results[0].highest_score.time)
              }}
            </p>
          </div>
        </div>
        <div class="grid justify-items-center" v-if="gameOver.game_live">
          <p class="font-InterExtraBold">Game closes in:</p>
          <div
            id="gameClosingTimer"
            class="flex gap-1 items-center mt-1 font-ZuumeSemiBold"
          >
            <div
              class="bg-nColorYellow border border-nColorDarkGray rounded-lg text-3xl w-12 text-center"
            >
              <span id="gameClosingTimerM">00</span>
              <span class="text-sm ml-0.5">m</span>
            </div>
            <span class="text-2xl">:</span>
            <div
              class="bg-nColorYellow border border-nColorDarkGray rounded-lg text-3xl w-12 text-center"
            >
              <span id="gameClosingTimerS">00</span>
              <span class="text-sm ml-0.5">s</span>
            </div>
          </div>
        </div>
        <div
          class="grid justify-items-center"
          v-else-if="gameOver.leaderboard_timer"
        >
          <p class="font-InterExtraBold">Results will be announced in:</p>
          <div
            id="leaderboardTimer"
            class="flex gap-1 items-center mt-1 font-ZuumeSemiBold"
          >
            <div class="bg-nColorYellow rounded-lg text-3xl w-12 text-center">
              <span id="leaderboardTimerM">00</span>
              <span class="text-sm ml-0.5">m</span>
            </div>
            <span class="text-2xl">:</span>
            <div class="bg-nColorYellow rounded-lg text-3xl w-12 text-center">
              <span id="leaderboardTimerS">00</span>
              <span class="text-sm ml-0.5">s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 grid justify-items-center">
      <button
        class="shadow-yellow-right border-2 border-nColorYellow bg-black rounded-3xl w-5/6 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        @click.prevent="checkRatingAndGoToGame()"
        v-if="gameOver.game_live"
      >
        Play again
      </button>
      <button
        class="shadow-orange-right border border-nColorDarkGray bg-nColorYellow rounded-3xl w-5/6 text-nColorDarkGray text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none mt-3"
        @click.prevent="checkRatingAndGoToLeaderboard()"
      >
        Leaderboard
      </button>
    </div>
    <div id="ratingPopUp" class="hidden">
      <div
        id="overlay-blur"
        class="absolute top-0 w-screen h-screen bg-nColorDarkGray blurry-div z-30"
      ></div>
      <div
        class="w-10/12 p-4 bg-nColorYellow rounded-xl absolute transform left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-40 pb-10"
      >
        <div class="absolute -top-4 right-4">
          <img
            :src="require('@/assets/images/rating/close.svg')"
            @click.prevent="closeRatingPopup"
          />
        </div>
        <p
          class="text-nColorOrange font-ZuumeSemiBold text-4xl text-center mt-2 text-shadow-black-right-light"
        >
          Rate this game
        </p>
        <p class="font-InterSemiBold text-sm text-center">
          Your opinion matters to us.
        </p>
        <div
          class="w-10/12 bg-nColorOrange rounded-3xl text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-1 gap-2 absolute transform left-1/2 -translate-x-1/2 -bottom-5 border border-nColorDarkGray shadow-yellow-right"
        >
          <div class="flex gap-2">
            <img
              :src="require('@/assets/images/rating/1.svg')"
              id="1star"
              @click.prevent="selectRating('1')"
            />
            <img
              :src="require('@/assets/images/rating/2.svg')"
              id="2star"
              @click.prevent="selectRating('2')"
            />
            <img
              :src="require('@/assets/images/rating/3.svg')"
              id="3star"
              @click.prevent="selectRating('3')"
            />
            <img
              :src="require('@/assets/images/rating/4.svg')"
              id="4star"
              @click.prevent="selectRating('4')"
            />
            <img
              :src="require('@/assets/images/rating/5.svg')"
              id="5star"
              @click.prevent="selectRating('5')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import slugs from "@/common/slugs";

export default {
  components: {},
  data() {
    return {
      gameOver: null,
      redirectTo: "leaderboard",
      slugs,
    };
  },
  beforeUnmount() {
    if (this.cutoffTimerStarted) {
      this.clearCutoffTimer();
    }
    if (this.leaderboardTimerStarted) {
      this.clearLeaderboardTimer();
    }
  },
  created() {
    ApiService.get(apiResource.getGameOverDetails, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
      game_session_id: this.$route.params.game_session_id,
    }).then((data) => {
      this.gameOver = data.data.data;
      if (this.gameOver.game_live) {
        this.setGameCutoffDateTime(this.gameOver.details.cutoff_datetime);
        this.startGameClosingTimer();
      } else if (this.gameOver.leaderboard_timer) {
        this.setLeaderboardDateTime(this.gameOver.details.leaderboard_datetime);
        this.startLeaderboardTimer();
      }
    });
  },
  mounted() {},
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    setGameCutoffDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [day, month, year] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.gameCutoffTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
    },
    setLeaderboardDateTime(dateTime) {
      const dateTimeString = dateTime;
      const [dateString, timeString] = dateTimeString.split("T");
      const [day, month, year] = dateString.split("-");
      const formattedDate = `${year}-${month}-${day}`;
      const [hour, minute, second] = timeString.split(":");
      this.leaderboardTime = new Date(
        `${formattedDate}T${hour}:${minute}:${second}`
      );
    },
    getTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.gameCutoffTime - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    getLeaderboardTimeCalculation() {
      const currentTime = new Date();
      const remainingTime = Math.max(0, this.leaderboardTime - currentTime);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60)).toLocaleString(
        "en-US",
        { minimumIntegerDigits: 2 }
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      const seconds = Math.floor(
        (remainingTime % (1000 * 60)) / 1000
      ).toLocaleString("en-US", { minimumIntegerDigits: 2 });
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        remainingTime: remainingTime,
      };
    },
    startGameClosingTimer() {
      setTimeout(() => {
        const timerElementM = document.getElementById("gameClosingTimerM");
        const timerElementS = document.getElementById("gameClosingTimerS");
        this.cutoffTimerStarted = true;
        this.cutoffIntervalId = setInterval(() => {
          const data = this.getTimeCalculation();
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            window.location.reload();
          }
        }, 1000);
      }, 500);
    },
    startLeaderboardTimer() {
      setTimeout(() => {
        const timerElementM = document.getElementById("leaderboardTimerM");
        const timerElementS = document.getElementById("leaderboardTimerS");
        this.leaderboardTimerStarted = true;
        this.leaderboardIntervalId = setInterval(() => {
          const data = this.getLeaderboardTimeCalculation();
          timerElementM.innerHTML = data.minutes;
          timerElementS.innerHTML = data.seconds;
          if (data.remainingTime <= 0) {
            if (!this.gameOver.has_given_feedback) {
              this.rateTheGame("leaderboard");
            } else {
              this.goToLeaderboard();
            }
          }
        }, 1000);
      }, 500);
    },
    clearCutoffTimer() {
      clearInterval(this.cutoffIntervalId);
      this.cutoffTimerStarted = false;
    },
    clearLeaderboardTimer() {
      clearInterval(this.leaderboardIntervalId);
      this.leaderboardTimerStarted = false;
    },
    goToLeaderboard() {
      this.$router.push({
        name: "Leaderboard",
        params: {
          business: this.$route.params.business,
          schedule_id: this.$route.params.schedule_id,
        },
      });
    },
    rateTheGame(goTo) {
      this.redirectTo = goTo;
      document.getElementById("ratingPopUp").classList.remove("hidden");
    },
    closeRatingPopup() {
      document.getElementById("ratingPopUp").classList.add("hidden");
    },
    checkRatingAndGoToGame() {
      if (!this.gameOver.has_given_feedback) {
        this.rateTheGame("game");
      } else {
        this.goToGame();
      }
    },
    checkRatingAndGoToLeaderboard() {
      if (!this.gameOver.has_given_feedback) {
        this.rateTheGame("leaderboard");
      } else {
        this.goToLeaderboard();
      }
    },
    goToGame() {
      this.$router.push({
        name: "Game",
        params: {
          business: this.$route.params.business,
          schedule_id: this.$route.params.schedule_id,
          game: this.gameOver.meta.slug,
        },
      });
    },
    selectRating(rating) {
      for (var i = 1; i <= 5; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + ".svg");
      }
      for (i = 1; i <= rating; i++) {
        document.getElementById(
          i + "star"
        ).src = require("@/assets/images/rating/" + i + "-selected.svg");
      }
      ApiService.post(apiResource.saveGameFeedback, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        rating: rating,
      })
        .then(() => {
          if (this.redirectTo == "leaderboard") {
            this.goToLeaderboard();
          } else if (this.redirectTo == "game") {
            this.goToGame();
          }
        })
        .catch(() => {
          if (this.redirectTo == "leaderboard") {
            this.goToLeaderboard();
          } else if (this.redirectTo == "game") {
            this.goToGame();
          }
        });
    },
  },
};
</script>

<style scoped lang="postcss">
.gameover {
  height: 100dvh;
}
.game-over-text {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #231f20;
}
.blurry-div {
  background-color: rgba(35, 31, 32, 0.85);
}
</style>
